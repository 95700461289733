/* eslint-disable max-lines */
import { completeTypes, createTypes, withPostSuccess, withPostFailure, withSuccess } from 'redux-recompose';
import { t } from 'i18next';
import { push } from 'connected-react-router';

import OrderService from '~services/Order/service';
import SearchStore from '~services/SearchStore/service';
import CallCenterService from '~services/Callcenter/service';
import { deserializer } from '~services/baseSerializers';
import { CLIENT_PAGES } from '~constants/pages';
import {
  INTERNAL_PAYMENT_MODAL,
  ORDER_CONFIRMATION_MODAL,
  PAYMENT_BRICK_MODAL,
  QUICK_ORDER_MODAL
} from '~redux/Modal/constants';
import { NOTIFICATION_DANGER } from '~redux/Notification/constants';
import { actionCreators as notificationActions } from '~redux/Notification/actions';
import { actionCreators as searchStoreActions } from '~redux/SearchStore/actions';
import { actionCreators as customPizzaActions } from '~redux/CustomPizza/actions';
import { actionCreators as authActions } from '~redux/Auth/actions';
import { actionCreators as modalActions } from '~redux/Modal/actions';
import {
  actionCreators as menuActions,
  privateActionCreators as menuOrderActions
} from '~screens/Dashboard/screens/Home/redux/actions';
import { onlyContainDigitalItems } from '~models/order';
import localStorageService from '~services/LocalStorageService';
import { useGiftCardGTMEvent } from '~gtmEvents/giftCards';
import { CROSS_SELLING_COOKIE } from '~screens/Dashboard/components/Modals/constants';
import { deleteCookie } from '~utils/cookies';

import { filterResponseFailed, redirectPost, redirectToExternal, sendFailedPurchase } from './utils';
import {
  CURRENT_ORDER_TARGET,
  ACTIVE_ORDERS_TARGET,
  PAST_ORDERS_TARGET,
  STORE_TARGET,
  ONLINE_PAYMENT_TARGET,
  ORDER_POLL_TARGET,
  ERROR_UNPROCESSABLE_ITEMS,
  INGENICO_URL_TARGET,
  ORDER_POLL_STATUS_TARGET,
  STORES_TARGET,
  INTERVAL_REQUEST,
  PAYMENT_STATUS,
  EXTERNAL_ERROR_ORDER_TARGET,
  INGENICO_3DS_HTML_TARGET,
  CHECKOUT_BUTTON_STATUS_TARGET,
  AWIN_KEY_TARGET,
  FPAY_URL_TARGET,
  APPLEPAY_URL_TARGET,
  BIZUM_URL_TARGET,
  ORDER_STATUSES
} from './constants';

const completedTypes = completeTypes(
  [
    'GET_CURRENT_ORDER',
    'GET_ACTIVE_ORDERS',
    'GET_STORE',
    'SET_STORE',
    'GET_PAST_ORDERS',
    'ONLINE_PAYMENT',
    'GET_ORDER_POLL',
    'SEND_ORDER_POLL',
    'SET_FAKE_STORE',
    'GET_STORE_FOR_ACTIVE_ORDERS',
    'CONCATENATE_STORES'
  ],
  ['SET_VALUE', 'CLEAR_ALL_ORDER_DATA', 'SET_INGENICO_URL', 'SET_FPAY_URL', 'SET_BIZUM_URL']
);

export const actions = createTypes(completedTypes, '@@ORDER');

export const actionCreators = {
  clearAllOrderData: () => ({
    type: actions.CLEAR_ALL_ORDER_DATA
  }),
  getCurrentOrder: () => (dispatch, getState) => {
    const { auth } = getState();
    dispatch({
      type: actions.GET_CURRENT_ORDER,
      target: CURRENT_ORDER_TARGET,
      service: OrderService.getCurrentOrder,
      payload: auth.guestUser?.email || auth.currentUser?.email,
      successSelector: ({ data }) => (data ? deserializer.serialize(data) : {}),
      injections: [
        withPostSuccess((_, response) => {
          const order = response.data ? deserializer.serialize(response.data) : {};
          if (
            onlyContainDigitalItems(order) &&
            (order.paymentStatus === PAYMENT_STATUS.COMPLETED || order.status === ORDER_STATUSES.FULFILLED)
          ) {
            localStorageService.setLastActiveOrder(order);
          }
        })
      ]
    });
  },
  getActiveOrders: onlinePayment => (dispatch, getState) => {
    const { order, auth } = getState();
    dispatch({
      type: actions.GET_ACTIVE_ORDERS,
      target: ACTIVE_ORDERS_TARGET,
      service: OrderService.getActiveOrders,
      payload: auth.guestUser?.email || auth.currentUser?.email,
      successSelector: ({ data }) => (data ? deserializer.serialize(data) : []),
      injections: [
        withPostSuccess((_, response) => {
          if (response.data) {
            dispatch(actionCreators.getCurrentOrder());
            const storesId = [];
            // eslint-disable-next-line array-callback-return
            response.data.map(({ store_id: orderStoreId, dispatch_method: dispatchMethod }) => {
              if (
                !order.stores?.find(store => store.id === orderStoreId) &&
                !storesId.find(storeId => storeId === orderStoreId)
              ) {
                dispatch(actionCreators.getStoreForActiveOrders(orderStoreId, dispatchMethod));
                storesId.push(orderStoreId);
              }
            });
          }
          if (onlinePayment) {
            dispatch(actionCreators.onlinePaymentToggleLoading(false));
          }
        }),
        withPostFailure(() => {
          if (onlinePayment) {
            dispatch(actionCreators.onlinePaymentToggleLoading(false));
          }
        })
      ]
    });
  },
  getPastOrders: page => ({
    type: actions.GET_PAST_ORDERS,
    target: PAST_ORDERS_TARGET,
    service: OrderService.getPastOrders,
    payload: page,
    successSelector: response => filterResponseFailed(response)
  }),
  getCallCenterPastOrders: externalId => ({
    type: actions.GET_PAST_ORDERS,
    target: PAST_ORDERS_TARGET,
    service: CallCenterService.getUserOrders,
    payload: externalId,
    successSelector: response => filterResponseFailed(response)
  }),
  getStore: (id, dispatchType, offerId) => ({
    type: actions.GET_STORE,
    target: STORE_TARGET,
    service: SearchStore.getStore,
    payload: { id },
    successSelector: response => (response.data ? deserializer.serialize(response.data) : {}),
    injections: [
      withPostSuccess((dispatch, response) => {
        if (response.data && dispatchType) {
          dispatch(searchStoreActions.setExternalStore(id));
          dispatch(
            searchStoreActions.selectSubsidiary(
              response.data ? { ...deserializer.serialize(response.data) } : {},
              false
            )
          );
          dispatch(authActions.setAddress(response.data));
          dispatch(searchStoreActions.setDispatchType(dispatchType));
          dispatch(
            menuActions.getMenu(
              id,
              dispatchType,
              () => dispatch(customPizzaActions.getIngredients()),
              offerId
            )
          );
        }
      })
    ]
  }),
  setStore: store => ({
    type: actions.SET_STORE,
    target: STORE_TARGET,
    payload: store
  }),
  getStoreForActiveOrders: id => ({
    type: actions.GET_STORE_FOR_ACTIVE_ORDERS,
    target: STORE_TARGET,
    service: SearchStore.getStore,
    payload: { id },
    successSelector: response => (response.data ? deserializer.serialize(response.data) : {}),
    injections: [
      withPostSuccess((dispatch, response) => {
        if (response.data) {
          dispatch(actionCreators.concatenateStores(deserializer.serialize(response.data)));
        }
      })
    ]
  }),
  concatenateStores: store => ({
    type: actions.CONCATENATE_STORES,
    target: STORES_TARGET,
    payload: [store]
  }),
  powertranzStartPayment: ({ orderId, cardId, cardCode, cardNumber, expiration }) => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: OrderService.powertranzStartPayment,
    payload: { orderId, cardId, cardCode, cardNumber, expiration },
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withSuccess((dispatch, response) => {
        dispatch(actionCreators.onlinePaymentToggleLoading(false));
        window.document.write(response.data.redirect_data);
      }),
      withPostFailure(dispatch => {
        dispatch(
          notificationActions.show({
            message: t('Home:messageErrorCreditCard'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  mercantilStartPayment: ({ orderId, cardId, cardCode, cardNumber, cardToken }) => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: OrderService.mercantilStartPayment,
    payload: { orderId, cardId, cardCode, cardNumber, cardToken },
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withSuccess((dispatch, response) => {
        if (response.data.challenge_url) {
          window.location.href = response.data.challenge_url;
          return;
        }
        if (response.data['success?']) {
          dispatch(actionCreators.confirmPayment());
        } else {
          dispatch(
            notificationActions.show({
              message: t('Home:messageErrorCreditCard'),
              type: NOTIFICATION_DANGER
            })
          );
        }
      }),
      withPostFailure(dispatch => {
        dispatch(
          notificationActions.show({
            message: t('Home:messageErrorCreditCard'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  ingenicoStartPayment: ({ orderId, createCard, cardId, ingenico3DSv2 }) => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: cardId ? OrderService.ingenicoCardStartPayment : OrderService.ingenicoStartPayment,
    payload: { orderId, createCard, ...(cardId && { cardId, ingenico3DSv2 }) },
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withPostSuccess((dispatch, response) => {
        const { status, htmlAnswer } = deserializer.serialize(response.data);
        if (status === PAYMENT_STATUS.SECURE_VALIDATION || htmlAnswer) {
          window.document.write(htmlAnswer);
        } else if (cardId) {
          dispatch(actionCreators.confirmPayment());
        } else {
          dispatch(actionCreators.ingenicoSetURL(response.data.payment_url));
          dispatch(modalActions.closeModal(QUICK_ORDER_MODAL));
          dispatch(modalActions.openModal(INTERNAL_PAYMENT_MODAL));
          dispatch(actionCreators.onlinePaymentToggleLoading(false));
        }
      }),
      withPostFailure(dispatch => {
        dispatch(
          notificationActions.show({
            message: t('Home:messageErrorOnlinePayment'),
            type: NOTIFICATION_DANGER
          })
        );
        dispatch(modalActions.closeModal(ORDER_CONFIRMATION_MODAL));
      })
    ]
  }),
  sinpeMovilStartPayment: ({ orderId, clientDocumentId }) => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: OrderService.sinpeMovilStartPayment,
    payload: { orderId, clientDocumentId },
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withPostSuccess(dispatch => {
        dispatch(actionCreators.confirmPayment());
      }),
      withPostFailure(dispatch => {
        dispatch(
          notificationActions.show({
            message: t('Home:messageErrorSinpeMovil'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  confirmPayment: () => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: OrderService.getCurrentOrder,
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withSuccess((dispatch, response) => {
        const order = response.data ? deserializer.serialize(response.data) : {};
        const { paymentStatus, status, externalError } = order;
        if (paymentStatus === PAYMENT_STATUS.COMPLETED || status === ORDER_STATUSES.FULFILLED) {
          if (onlyContainDigitalItems(order)) {
            localStorageService.setLastActiveOrder(order);
          }
          useGiftCardGTMEvent(order);
          dispatch(actionCreators.setExternalError(null));
          dispatch(menuOrderActions.createOrderSuccess(response.data));
          dispatch(actionCreators.onlinePaymentToggleLoading(false));
          dispatch(actionCreators.getActiveOrders());
          deleteCookie(CROSS_SELLING_COOKIE);
          dispatch(push(CLIENT_PAGES.PURCHASE_OK.path));
        } else if (status === ORDER_STATUSES.PAYMENT_FAILED) {
          sendFailedPurchase(dispatch, externalError);
        } else if (paymentStatus === PAYMENT_STATUS.UNKNOWN || paymentStatus === PAYMENT_STATUS.CREATED) {
          setTimeout(() => dispatch(actionCreators.confirmPayment()), INTERVAL_REQUEST);
        } else {
          sendFailedPurchase(dispatch, externalError);
        }
      }),
      withPostFailure(dispatch => {
        dispatch(push(CLIENT_PAGES.PURCHASE_FAIL.path));
      })
    ]
  }),
  oneclickStartPayment: ({ orderId, cardId, orderSuccessCallback }) => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: OrderService.oneclickStartPayment,
    payload: { orderId, cardId },
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withSuccess(dispatch => {
        dispatch(actionCreators.onlinePaymentToggleLoading(false));
        if (orderSuccessCallback) {
          dispatch(orderSuccessCallback());
        }
        dispatch(orderSuccessCallback());
        dispatch(modalActions.closeModal(QUICK_ORDER_MODAL));
        dispatch(modalActions.openModal(ORDER_CONFIRMATION_MODAL));
        dispatch(actionCreators.getCurrentOrder());
        dispatch(actionCreators.getActiveOrders());
        dispatch(push(CLIENT_PAGES.PURCHASE_OK.path));
      }),
      withPostFailure(dispatch => {
        dispatch(
          notificationActions.show({
            message: t('Home:messageErrorCreditCard'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  ingenicoSetURL: url => ({
    type: actions.SET_INGENICO_URL,
    target: INGENICO_URL_TARGET,
    payload: url
  }),
  ingenicoSet3dsHTML: html => ({
    type: actions.SET_INGENICO_3DS_HTML,
    target: INGENICO_3DS_HTML_TARGET,
    payload: html
  }),
  webPayStartPayment: orderId => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: OrderService.webPayStartPayment,
    payload: orderId,
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.onlinePaymentToggleLoading(true));
        redirectPost(response.data.url, response.data.token);
      }),
      withPostFailure(dispatch => {
        dispatch(push(CLIENT_PAGES.PURCHASE_FAIL.path));

        dispatch(
          notificationActions.show({
            message: t('Home:messageErrorWebPay'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  mercadoPagoStartPayment: (orderId, body) => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: OrderService.mercadoPagoStartPayment,
    payload: { orderId, body },
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withPostSuccess(dispatch => {
        dispatch(modalActions.closeModalWithData(PAYMENT_BRICK_MODAL));
        dispatch(actionCreators.onlinePaymentToggleLoading(false));
        setTimeout(() => dispatch(push(CLIENT_PAGES.PURCHASE_CONTROL.path)), 300);
      }),
      withPostFailure(dispatch => {
        dispatch(push(CLIENT_PAGES.PURCHASE_FAIL.path));

        dispatch(
          notificationActions.show({
            message: t('Home:messageErrorOnlinePayment'),
            type: NOTIFICATION_DANGER
          })
        );
        dispatch(modalActions.closeModal(ORDER_CONFIRMATION_MODAL));
        dispatch(modalActions.closeModalWithData(PAYMENT_BRICK_MODAL));
      })
    ]
  }),
  applePayStartPayment: orderId => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: OrderService.applePayStartPayment,
    payload: orderId,
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.onlinePaymentToggleLoading(true));
        redirectToExternal(response.data.url);
      }),
      withPostFailure(dispatch => {
        dispatch(push(CLIENT_PAGES.PURCHASE_FAIL.path));

        dispatch(
          notificationActions.show({
            message: t('Home:messageErrorWebPay'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  sodexoStartPayment: orderId => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: OrderService.sodexoStartPayment,
    payload: orderId,
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.onlinePaymentToggleLoading(true));
        redirectToExternal(response.data?.url);
      }),
      withPostFailure(dispatch => {
        dispatch(push(CLIENT_PAGES.PURCHASE_FAIL.path));
        dispatch(
          notificationActions.show({
            message: t('Home:messageErrorSodexo'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  fpayStartPayment: orderId => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: OrderService.fpayStartPayment,
    payload: orderId,
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.fpaySetURL(response.data?.url));
        dispatch(modalActions.closeModal(QUICK_ORDER_MODAL));
        dispatch(modalActions.openModal(INTERNAL_PAYMENT_MODAL));
        dispatch(actionCreators.onlinePaymentToggleLoading(false));
      }),
      withPostFailure(dispatch => {
        dispatch(
          notificationActions.show({
            message: t('Home:messageErrorOnlinePayment'),
            type: NOTIFICATION_DANGER
          })
        );
        dispatch(modalActions.closeModal(ORDER_CONFIRMATION_MODAL));
      })
    ]
  }),
  bizumStartPayment: orderId => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: OrderService.bizumStartPayment,
    payload: orderId,
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.onlinePaymentToggleLoading(true));
        redirectToExternal(response.data.url);
      }),
      withPostFailure(dispatch => {
        dispatch(push(CLIENT_PAGES.PURCHASE_FAIL.path));
        dispatch(
          notificationActions.show({
            message: t('Home:messageErrorOnlinePayment'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  brickStartPayment: order => dispatch => {
    dispatch(modalActions.closeModal(QUICK_ORDER_MODAL));
    dispatch(
      modalActions.openModalWithData(PAYMENT_BRICK_MODAL, {
        orderId: order.id,
        amount: order.total_price
      })
    );
    dispatch(modalActions.closeModal(ORDER_CONFIRMATION_MODAL));
    dispatch(actionCreators.onlinePaymentToggleLoading(false));
  },
  fpaySetURL: url => ({
    type: actions.SET_FPAY_URL,
    target: FPAY_URL_TARGET,
    payload: url
  }),
  applepaySetURL: url => ({
    type: actions.SET_APPLEPAY_URL,
    target: APPLEPAY_URL_TARGET,
    payload: url
  }),
  bizumSetURL: url => ({
    type: actions.SET_BIZUM_URL,
    target: BIZUM_URL_TARGET,
    payload: url
  }),
  paypalStartPayment: orderId => ({
    type: actions.ONLINE_PAYMENT,
    target: ONLINE_PAYMENT_TARGET,
    service: OrderService.paypalStartPayment,
    payload: orderId,
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.onlinePaymentToggleLoading(true));
        redirectToExternal(response.data.approve_url);
      }),
      withPostFailure(dispatch => {
        dispatch(push(CLIENT_PAGES.PURCHASE_FAIL.path));

        dispatch(
          notificationActions.show({
            message: t('Home:messageErrorOnlinePayment'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  onlinePaymentToggleLoading: value => ({
    type: actions.SET_VALUE,
    target: `${ONLINE_PAYMENT_TARGET}Loading`,
    payload: value
  }),
  setExternalError: value => ({
    type: actions.SET_VALUE,
    target: EXTERNAL_ERROR_ORDER_TARGET,
    payload: value
  }),
  currentOrderToggleLoading: value => ({
    type: actions.SET_VALUE,
    target: `${CURRENT_ORDER_TARGET}Loading`,
    payload: value
  }),
  getOrderPoll: (orderId, userId) => ({
    type: actions.GET_ORDER_POLL,
    target: ORDER_POLL_TARGET,
    service: OrderService.getOrderPoll,
    payload: { orderId, userId },
    injections: [
      withPostFailure(dispatch => {
        dispatch(actionCreators.setOrderPollStatus(true));
      }),
      withPostSuccess(dispatch => {
        dispatch(actionCreators.setOrderPollStatus(false));
      })
    ]
  }),
  setOrderPollStatus: status => ({
    type: actions.SET_VALUE,
    target: ORDER_POLL_STATUS_TARGET,
    payload: status
  }),
  sendOrderPoll: (values, userId) => ({
    type: actions.SEND_ORDER_POLL,
    service: OrderService.sendOrderPoll,
    payload: { values, userId }
  }),
  postFailurePaymentMethods: (dispatch, response, message) => {
    dispatch(actionCreators.onlinePaymentToggleLoading(true));
    dispatch(actionCreators.getActiveOrders(true));
    if (response.data.errors?.description !== ERROR_UNPROCESSABLE_ITEMS) {
      dispatch(
        notificationActions.show({
          message,
          type: NOTIFICATION_DANGER
        })
      );
    }
  },
  checkoutButtonToggleLoading: value => ({
    type: actions.SET_VALUE,
    target: `${CHECKOUT_BUTTON_STATUS_TARGET}Loading`,
    payload: value
  }),
  setAwinKey: value => ({
    type: actions.SET_VALUE,
    target: AWIN_KEY_TARGET,
    payload: value
  })
};
