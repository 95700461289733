import { Helmet } from 'react-helmet';

import { BASE_URL, CONFIG_COUNTRIES } from '~constants/environment';
import { PATHS } from '~constants/paths';

const currentCountry = process.env.REACT_APP_ISO_COUNTRY;

export function CanonicalAlternateHeadLink() {
  const [, matchPath] =
    Object.entries(PATHS).find(([, path]) => Object.values(path).includes(window.location.pathname)) ?? [];

  if (!matchPath) {
    return null;
  }

  return (
    <Helmet>
      {Object.entries(CONFIG_COUNTRIES)
        .map(([country, { locale, tdl }]) => [
          country,
          locale,
          BASE_URL.replace(CONFIG_COUNTRIES[currentCountry].tdl, tdl)
        ])
        .map(([country, locale, href]) => (
          <link
            key={country}
            rel={currentCountry === country ? 'canonical' : 'alternate'}
            href={
              matchPath.default === PATHS.home.default
                ? href
                : `${href}${matchPath[country] ?? matchPath.default}`
            }
            {...(currentCountry === country ? {} : { hrefLang: locale })}
          />
        ))}
    </Helmet>
  );
}
