export const SNACKBAR_COOKIES_ENABLED = process.env.REACT_APP_SNACKBAR_COOKIES_ENABLED === String(true);

export const USE_PLACES_ADDRESS = process.env.REACT_APP_USE_PLACES_ADDRESS === String(true);

export const TUTORIAL_ENABLED = process.env.REACT_APP_TUTORIAL_ENABLED === String(true);

export const ELECTRONIC_RECEIPT_ENABLED = process.env.REACT_APP_ELECTRONIC_RECEIPT_ENABLED === String(true);

export const SELECT_DISPATCH_TIME_ENABLED =
  process.env.REACT_APP_SELECT_DISPATCH_TIME_ENABLED === String(true);

export const DYNAMIC_PRICES_ENABLED = process.env.REACT_APP_DYNAMIC_PRICES_ENABLED === String(true);

export const SHARE_CODE_ENABLED = process.env.REACT_APP_SHARE_CODE_ENABLED === String(true);

export const SELLIGENT_ENABLED = process.env.REACT_APP_SELLIGENT === String(true);

export const SELLIGENT_TRACKING_ENABLED = process.env.REACT_APP_SELLIGENT_SITE === String(true);

export const GOOGLE_OPTIMIZE_ENABLED = process.env.REACT_APP_GOOGLE_OPTIMIZE === String(true);

export const AWIN_ENABLED = process.env.REACT_APP_AWIN === String(true);

export const CROSS_SELLING = process.env.REACT_APP_CROSS_SELLING === String(true);

export const UPSELLING = process.env.REACT_APP_UPSELLING === String(true);

export const WHATSAPP_ENABLE = process.env.REACT_APP_WHATSAPP_ENABLE === String(true);

export const PUSH_ALERT_ENABLED = process.env.REACT_APP_PUSH_ALERT_ID?.length > 1;

export const SAUCES_AT_CHECKOUT_ENABLED = process.env.REACT_APP_SAUCES_AT_CHECKOUT_ENABLED === String(true);
