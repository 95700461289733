import i18next from 'i18next';

i18next.addResources('es_CL', 'ProfileCard', {
  EditFieldProfile: 'Edición de perfil',
  EditFieldName: 'Edición de nombre',
  closeEditFieldName: 'Cancelar edición de nombre',
  EditPassword: 'Edición de contraseña',
  preferencesTitle: 'Preferencias comunicacionales',
  preferencesSubtitle: 'Administra las opciones de comunicación de tu cuenta',
  checkboxExcluviseInformation:
    'Deseo recibir información exclusiva de lanzamientos, promociones, descuentos y cupones.',
  checkboxNPSEmail: 'No deseo recibir correos para evaluar mi experiencia',
  edit: 'Editar',
  cancel: 'Cancelar',
  saveChanges: 'Guardar cambios',
  noBirthdayDate: 'No has ingresado tu cumpleaños',
  newPassword: 'Nueva contraseña',
  canNotEdit: 'Si deseas modificar esto deberás contactar al área de soporte',
  here: 'aquí.',
  acceptPoll: 'No deseo recibir correos para evaluar mi experiencia.'
});

i18next.addResources('pt_PT', 'ProfileCard', {
  EditFieldName: 'Edição de nome',
  closeEditFieldName: 'Cancelar edição de nome',
  EditFieldProfile: 'Edição de perfil',
  EditPassword: 'Edição de senha',
  saveChanges: 'Guardar mudanças',
  noBirthdayDate: 'Não inseriste o teu aniversário',
  newPassword: 'Nova senha',
  preferencesTitle: 'Preferências de comunicação',
  preferencesSubtitle: 'Gere as opções de comunicação da tua conta',
  checkboxExcluviseInformation:
    'Desejo receber informações exclusivas de lançamentos, promoções, descontos e cupões.',
  checkboxNPSEmail: 'Não Desejo receber e-mails para avaliar a minha experiência',
  canNotEdit: 'Se desejas alterar este campo, deverás contactar a área de suporte',
  here: 'aqui.',
  acceptPoll: 'No deseo recibir correos para evaluar mi experiencia.'
});

i18next.addResources('es_ES', 'ProfileCard', {
  preferencesTitle: 'Preferencias de comunicación',
  noBirthdayDate: 'No has introducido tu fecha de cumpleaños',
  canNotEdit: 'Si deseas modificar esto, deberás contactar con el área de soporte'
});
