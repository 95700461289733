export const LOGIN = 'login';

export const REGISTER_MODAL = 'register';

export const PIZZA_BY_HALVES_MODAL = 'pizzaByHalves';

export const PIZZA_BY_HALVES_MODAL_PB = 'pizzaByHalvesPB';

export const STORE_CLOSED = 'storeClosed';

export const CREATE_PIZZA_MODAL = 'createPizza';

export const ORDER_CONFIRMATION_MODAL = 'orderConfirmation';

export const COMBO_MODAL = 'combo';

export const ADD_ADDRESS_MODAL = 'addAddress';

export const ADD_PAYMENT_MODAL = 'addPayment';

export const CHANGE_ADDRESS = 'changeAddress';

export const MISSING_ITEMS = 'missingItems';

export const SELECT_ADDRESS_MODAL = 'contactSelectAddress';

export const PIZZAS_TUTORIAL = 'pizzasTutorial';

export const INTERNAL_PAYMENT_MODAL = 'internalPayment';

export const UPDATE_PASSWORD = 'updatePassword';

export const ADD_CARD = 'addCard';

export const ADD_COMPANY_MODAL = 'addCompany';

export const DELETE_CREDIT_CARD_MODAL = 'deleteCreditCardModal';

export const SELECT_DISPATCH_TIME = 'selectTimeDispatch';

export const OPTIMIZED_CART_MODAL = 'optimizedCart';

export const OFFER_COMPLETION_MODAL = 'offerCompletion';

export const CHANGE_PHONE_MODAL = 'changePhone';

export const COMPANY_LIST_MODAL = 'companyList';

export const ORDER_RECEIPT_MODAL = 'orderReceipt';

export const ORDER_RULE_MODAL = 'orderRule';

export const PROMOTION_MODAL = 'promotionModal';

export const NEW_GUEST_USER_MODAL = 'newGuestUser';

export const BLOCKED_PRODUCT_MODAL = 'blockedProductModal';

export const CHANGE_INFO_USER_MODAL = 'changeInfoUser';

export const CANCEL_ORDER_MODAL = 'cancelOrderModal';

export const CONFIRMED_ORDER_MODAL = 'confirmedOrderModal';

export const COOKIE_SETTINGS = 'cookieSettingsModal';

export const UP_SELLING_MODAL = 'upSellingModal';

export const AGE_VERIFICATION_MODAL = 'ageVerificationModal';

export const POPUP_RETARGETING_MODAL = 'popUpRetargetingModal';

export const CROSS_SELLING_MODAL = 'crossSellingModal';

export const OB_MONITOR_MODAL = 'obMonitorModal';

export const JOIN_PAPA_POINTS_MODAL = 'joinPapaPointsModal';

export const PRODUCT_OPTIONS_MODAL = 'productOptionsModal';

export const CART_CONTENT_MODAL = 'cartContentModal';

export const PIZZA_BY_HALVES_MODAL_V2 = 'pizzaByHalvesModalV2';

export const PAYMENT_METHODS_MODAL = 'paymentMethodsModal';

export const ELECTRONIC_RECEIPT_SIDE_MODAL = 'electronicReceiptSideModal';

export const EXISTING_USER = 'existingUser';

export const CART_PRODUCTS = 'cartProducts';

export const UPDATAE_PASS_MODAL = 'updatePasswordModal';

export const PRIVACY_POLICY_OR_TERMS_MODAL = 'privacyPolicyOrTermsModal';

export const TIME_UP_MODAL = 'timeUpModal';

export const BASE_PIZZA_LIST_MODAL = 'basePizzaListModal';

export const PAYMENT_BRICK_MODAL = 'paymentBrickModal';

export const PAPA_POINTS_INFORMATION_MODAL = 'PapaPointsInformationModal';

export const SINPE_MOVIL_MODAL = 'sinpeMovilModal';

export const DISABLED_ITEMS_MODAL = 'disabledItemsModal';

export const QUICK_ORDER_MODAL = 'quickOrder';
