import i18next from 'i18next';

i18next.addResources('es_CL', 'Delivery', {
  notEnoughData: 'Nos faltan datos',
  description: 'Completa los datos del delivery',
  time: '{{time}} min aprox',
  confirmButton: 'Confirmar',
  apartmentPlaceholder: 'Piso / Oficina / Casa',
  notePlaceholder: 'Agregar nota de delivery',
  note: 'Nota de delivery',
  exclusionZone: 'No hay delivery disponible',
  exclusionZoneMessage: 'Lo sentimos, por ahora no podemos entregar en tu dirección',
  noStreetAddress: 'Escribe tu dirección completa para habilitar la entrega a domicilio.',
  noDeliveryButton: 'Cambiar a retiro en tienda',
  placeholder: '',
  notePlaceholderEx: '',
  storeIsClosed: 'La tienda {{name}} se encuentra momentáneamente sin delivery.',
  changeToPickUp: 'Cambia a retiro en punto para ver otras opciones.',
  confirmAndGoToCheckoutBtn: 'Confirmar e ir al checkout',
  chooseATime: 'Debes elegir una hora de entrega para continuar',
  saveAddress: 'Quiero guardar esta dirección',
  buildingType: 'Tipo de edificio',
  withConfirmAddressButton: 'Comenzar mi pedido',
  noteHelperText: ''
});

i18next.addResources('es_CR', 'Delivery', {
  notePlaceholder: 'Agregar nota de entrega',
  noStreetAddress: 'Escribe tu dirección completa para habilitar la opción de Delivery'
});

i18next.addResources('es_ES', 'Delivery', {
  description: 'Completa los datos de entrega',
  placeholder: 'Ej: Piso 5 Depto. 517',
  notePlaceholder: 'Comentarios adicionales',
  notePlaceholderEx: 'Ej: Dejar en la puerta',
  chooseATime: 'Selecciona una hora de entrega.'
});

i18next.addResources('pt_PT', 'Delivery', {
  notEnoughData: 'Estamos com dados ausentes',
  description: 'Preencha as suas informações de morada',
  time: '{{time}} min aprox',
  confirmButton: 'Confirmar',
  apartmentPlaceholder: 'Piso / Escritório / Casa',
  notePlaceholder: 'Deixe um comentário para o local',
  exclusionZone: 'Nenhuma entrega disponível',
  exclusionZoneMessage: 'Desculpe, por enquanto não podemos entregar para o seu endereço',
  noStreetAddress: 'Digite seu endereço completo para ativar a entrega em domicílio',
  noDeliveryButton: 'Mudar para recolha na loja',
  storeIsClosed: 'A loja {{name}} encontra-semomentaneamente sem entrega ao domicílio.',
  changeToPickUp: 'Muda para recolha na loja para ver outras opções.',
  confirmAndGoToCheckoutBtn: 'Confirmar e passar ao checkout',
  chooseATime: 'É necessário escolher uma hora de entrega para continuar',
  saveAddress: 'Salvar este endereço',
  buildingType: 'Tipo de edifício'
});

i18next.addResources('es_PA', 'Delivery', {
  note: 'Instrucciones de entrega',
  notePlaceholder: 'Agregar instrucciones de delivery'
});

i18next.addResources('es_GT', 'Delivery', {
  notePlaceholder: 'Instrucciones adicionales *',
  apartmentPlaceholder: 'Piso / Oficina / Casa *',
  noteHelperText: 'Ej: Casa con portón negro'
});
