import * as yup from 'yup';
import { t } from 'i18next';

export const getInstructions = ({ amount, phoneSinpeMovil }) => [
  {
    id: 'step1',
    number: t('SinpeMovilModal:instruction1.number'),
    text: t('SinpeMovilModal:instruction1.text', { amount, phoneSinpeMovil })
  },
  {
    id: 'step2',
    number: t('SinpeMovilModal:instruction2.number'),
    text: t('SinpeMovilModal:instruction2.text')
  },
  {
    id: 'step3',
    number: t('SinpeMovilModal:instruction3.number'),
    text: t('SinpeMovilModal:instruction3.text')
  }
];

const idValidations = {
  [t('SinpeMovilModal:identificationTypeOptions.Cédula Física')]: {
    code: 1,
    regex: /^0[1-9]{1}-\d{4}-\d{4}$/,
    length: 12
  },
  [t('SinpeMovilModal:identificationTypeOptions.Cédula Jurídica')]: {
    code: 4,
    regex: /^3-\d{3}-\d{6}$/,
    length: 10
  },
  [t('SinpeMovilModal:identificationTypeOptions.Extranjero no residente')]: {
    code: 7,
    regex: /^9.\{1,19}$/,
    length: 20
  },
  Dimex: {
    code: 8,
    regex: /^1[0-9]{11}$/,
    length: 12
  },
  Didi: {
    code: 10,
    regex: /^5[0-9]{11}$/,
    length: 12
  }
};

export const getIdentificationValidation = type => {
  const validation = idValidations[type];
  if (!validation) {
    throw new Error(`Unsupported identification type: ${type}`);
  }

  return yup
    .string()
    .matches(validation.regex, 'Formato inválido')
    .length(validation.length, `La identificacion debe tener ${validation.length} dígitos`);
};
