import i18next from 'i18next';

import { PRODUCT_CATEGORIES } from './constants';

i18next.addResources('es_CL', 'Menu', {
  [PRODUCT_CATEGORIES.PIZZA]: 'Pizzas',
  [PRODUCT_CATEGORIES.DESSERT]: 'Postres',
  [PRODUCT_CATEGORIES.SIDE]: 'Acompañamientos',
  [PRODUCT_CATEGORIES.DRINK]: 'Bebidas',
  [PRODUCT_CATEGORIES.EXTRA]: 'Extras',
  [PRODUCT_CATEGORIES.PROMO]: 'Promociones',
  [PRODUCT_CATEGORIES.PIZZA_PREMIUM]: 'Premium',
  [PRODUCT_CATEGORIES.PIZZA_SPECIALTY]: 'Especialidades',
  [PRODUCT_CATEGORIES.TRADITIONAL]: 'Tradicionales'
});

i18next.addResources('es_ES', 'Menu', {
  [PRODUCT_CATEGORIES.SIDE]: 'Entrantes'
});

i18next.addResources('pt_PT', 'Menu', {
  [PRODUCT_CATEGORIES.PIZZA]: 'Pizzas',
  [PRODUCT_CATEGORIES.DESSERT]: 'Sobremesas',
  [PRODUCT_CATEGORIES.SIDE]: 'Entradas',
  [PRODUCT_CATEGORIES.DRINK]: 'Bebidas',
  [PRODUCT_CATEGORIES.EXTRA]: 'Extras',
  [PRODUCT_CATEGORIES.PROMO]: 'Promoções',
  [PRODUCT_CATEGORIES.TRADITIONAL]: 'Tradicionais'
});

i18next.addResources('es_CL', 'PizzaMenu', {
  createPizzaTitle: 'Arma tu pizza',
  createPizzaTitleFirstPart: 'Arma tu',
  createPizzaTitleSecondPart: 'pizza',
  createPizzaDescription: 'Agrega tus ingredientes favoritos',
  pizzaHalvesTitle: 'Pizza por mitades',
  pizzaHalvesTitleFirstPart: 'Pizza por',
  pizzaHalvesTitleSecondPart: 'mitades',
  pizzaHalvesDescription: 'Pizza por mitades',
  titleStoreClosed: 'La tienda se encuentra cerrada',
  titleStoreClosedFutureOrder:
    'La tienda se encuentra cerrada, pero puedes programar tu orden para envío a domicilio.',
  subtitleStoreClosedDelivery: 'Los horarios de atención son los siguientes:',
  subtitleStoreClosedLocal: 'Por favor cambia tu pedido a envío a domicilio',
  unavailableForPickup: 'La tienda no se encuentra disponible para retiro en local',
  availableDelivery: 'Esta tienda sólo esta recibiendo pedidos por delivery.',
  addProduct: 'Producto agregado! 🍕'
});

i18next.addResources('es_CR', 'PizzaMenu', {
  createPizzaTitle: 'Crea tu pizza',
  createPizzaTitleFirstPart: 'Crea tu',
  createPizzaDescription: 'Elegí tus ingredientes favoritos'
});

i18next.addResources('es_ES', 'PizzaMenu', {
  createPizzaTitle: 'Crea tu pizza',
  createPizzaTitleFirstPart: 'Crea tu',
  createPizzaDescription: '',
  pizzaHalvesDescription: ''
});

i18next.addResources('pt_PT', 'PizzaMenu', {
  createPizzaTitle: 'Crie sua pizza',
  createPizzaTitleFirstPart: 'Crie sua',
  createPizzaDescription: '',
  pizzaHalvesTitle: 'Pizza por metades',
  pizzaHalvesTitleFirstPart: 'Pizza por',
  pizzaHalvesTitleSecondPart: 'metades',
  pizzaHalvesDescription: '',
  titleStoreClosed: 'A loja está fechada',
  titleStoreClosedFutureOrder:
    'A loja encontra-se fechada, mas podes programar o teu pedido para entrega em domicílio.',
  subtitleStoreClosedDelivery: 'As horas de atendimento ao público são:',
  subtitleStoreClosedLocal: 'Altere seu pedido para entrega em domicílio',
  unavailableForPickup: 'Esta loja não está disponível para entrega local.',
  availableDelivery:
    'Esta loja está recebendo pedidos de entrega, verifique seu endereço se houver entrega disponível.',
  addProduct: 'Produto agregado! 🍕'
});

i18next.addResources('es_PA', 'PizzaMenu', {
  createPizzaTitle: 'Crea tu pizza'
});
