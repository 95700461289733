/* eslint-disable max-lines */
import { object, string, number, bool, date } from 'yup';
import { t } from 'i18next';
import { validate } from 'rut.js';

import {
  PASSWORD_VALIDATION,
  PHONE_VALIDATION,
  NAME_VALIDATION,
  NUMBER_VALIDATION,
  CODE_VALIDATION,
  COUPON_VALIDATION,
  APARTMENT_VALIDATION,
  CURRENT_COUNTRY_CODE,
  COUNTRY_CODE_SYMBOL,
  REGEX,
  MAX_PLACES_ADDRESS_NOTE,
  CARD_TEST_STRING,
  DATE_TEST_STRING,
  REQUIRED_ADDRESS,
  LICENSE_PLATE_VALIDATION,
  PAPACAR_DESCRIPTION_VALIDATION,
  REGEX_CAR_OR_MOTO,
  CAPITAL_AVAILABLE_VALIDATION,
  AGE_VALIDATION,
  CARD_CODE_VALIDATION
} from '~constants/validation';
import { CURRENT_COUNTRY_ID } from '~constants/environment';
import { getIdentificationValidation } from '~screens/Dashboard/screens/Home/screens/Checkout/components/Modals/SinpeMovilModal/utils';

import { validateCreditCard, validateExpirationMonth, validatePastExpiryDate } from './functions';

export const getFormattedField = {
  phone: value => {
    if (!value) {
      return undefined;
    }

    return value.startsWith(CURRENT_COUNTRY_CODE)
      ? value.trim()
      : `${CURRENT_COUNTRY_CODE}${value?.replace(COUNTRY_CODE_SYMBOL, '').trim()}`;
  },
  email: value => value?.toLowerCase().trim()
};

export const NOT_REQUIRED_VALIDATION = {
  emailValidation: string().email(t('Field:invalid', { field: t('Field:email') }))
};

export const VALIDATIONS = {
  isRequired: bool(),
  mailCheckoutValidation: bool().required(),
  questionNPS: string().required(t('Field:required', { field: t('Field:generalName') })),
  emailValidation: string()
    .email(t('Field:invalid', { field: t('Field:email') }))
    .required(t('Field:required', { field: t('CheckoutPersonalDataForm:email') })),
  passwordValidation: string().required(t('Field:required', { field: t('Field:password') })),
  passwordCreation: string()
    .required(t('Field:required', { field: t('Field:password') }))
    .min(
      PASSWORD_VALIDATION.min,
      t('Field:min', { field: t('Field:password'), min: PASSWORD_VALIDATION.min })
    )
    .max(
      PASSWORD_VALIDATION.max,
      t('Field:max', { field: t('Field:password'), max: PASSWORD_VALIDATION.max })
    )
    .matches(REGEX.NOT_SPACES_AT_BEGIN_OR_END, t('Field:spaceAtBeginOrEnd'))
    .matches(REGEX.PASSWORD, {
      message: t('Field:passwordFormat')
    }),
  phoneCreation: string()
    .required(t('Field:required', { field: t('Register:phoneLabel') }))
    .min(CURRENT_COUNTRY_CODE.length + 1, t('Register:invalidPhone', { field: t('Register:phoneLabel') }))
    .matches(REGEX.ONLY_NUMBERS, t('Field:matches', { field: t('Register:phoneLabel') }))
    .matches(
      REGEX.COUNTRY_CODE,
      t('Field:codeCountry', { field: t('Register:phoneLabel'), code: CURRENT_COUNTRY_CODE })
    )
    .length(
      PHONE_VALIDATION[CURRENT_COUNTRY_ID].length,
      t('Field:length', {
        field: t('Register:phoneLabel'),
        length: PHONE_VALIDATION[CURRENT_COUNTRY_ID].length - 1
      })
    ),
  phoneCreationCheckout: string()
    .required(t('Field:required', { field: t('Field:phone') }))
    .length(
      PHONE_VALIDATION[CURRENT_COUNTRY_ID].length,
      t('Field:length', {
        field: t('Register:phoneLabel'),
        length: PHONE_VALIDATION[CURRENT_COUNTRY_ID].length - 1
      })
    ),
  birthdateCreation: date().max(new Date(), t('DatePicker:invalidDate')),
  faxCreation: string()
    .required(t('Field:required', { field: t('ElectronicReceiptForm:faxNumber') }))
    .min(
      CURRENT_COUNTRY_CODE.length + 1,
      t('Field:required', { field: t('ElectronicReceiptForm:faxNumber') })
    )
    .matches(REGEX.ONLY_NUMBERS, t('Field:matches', { field: t('ElectronicReceiptForm:faxNumber') }))
    .matches(
      REGEX.COUNTRY_CODE,
      t('Field:codeCountry', { field: t('ElectronicReceiptForm:faxNumber'), code: CURRENT_COUNTRY_CODE })
    )
    .length(
      PHONE_VALIDATION[CURRENT_COUNTRY_ID].length,
      t('Field:length', {
        field: t('ElectronicReceiptForm:faxNumber'),
        length: PHONE_VALIDATION[CURRENT_COUNTRY_ID].length - 1
      })
    ),
  capitalAvailableCreation: string()
    .min(
      CAPITAL_AVAILABLE_VALIDATION.min,
      t('Field:min', {
        field: t('FranchisesContactForm:inputCapitalAvailableInvestment'),
        min: CAPITAL_AVAILABLE_VALIDATION.min
      })
    )
    .max(
      CAPITAL_AVAILABLE_VALIDATION.max,
      t('Field:max', {
        field: t('FranchisesContactForm:inputCapitalAvailableInvestment'),
        max: CAPITAL_AVAILABLE_VALIDATION.max
      })
    )
    .matches(
      /^[0-9]+$/,
      t('Field:invalid', { field: t('FranchisesContactForm:inputCapitalAvailableInvestment') })
    ),
  ageValidation: number()
    .typeError(t('Field:invalid', { field: t('FranchisesContactForm:inputAge') }))
    .min(
      AGE_VALIDATION.min,
      t('FranchisesContactForm:ageRangeValidation', {
        min: AGE_VALIDATION.min,
        max: AGE_VALIDATION.max
      })
    )
    .max(
      AGE_VALIDATION.max,
      t('FranchisesContactForm:ageRangeValidation', {
        min: AGE_VALIDATION.min,
        max: AGE_VALIDATION.max
      })
    ),
  nameCreation: string()
    .required(t('Field:required', { field: t('CheckoutPersonalDataForm:name') }))
    .min(
      NAME_VALIDATION.min,
      t('Field:min', { field: t('CheckoutPersonalDataForm:name'), min: NAME_VALIDATION.min })
    )
    .max(
      NAME_VALIDATION.max,
      t('Field:max', { field: t('CheckoutPersonalDataForm:name'), max: NAME_VALIDATION.max })
    )
    .matches(REGEX.NOT_ONLY_WHITE_SPACES, t('Field:matches', { field: t('Field:onlyNameLowCase') }))
    .matches(REGEX.NORMAL_CHARACTERS, t('Field:matches', { field: t('Field:onlyNameLowCase') }))
    .matches(REGEX.NOT_SPACES_AT_BEGIN_OR_END, t('Field:spaceAtBeginOrEnd')),
  comercialNameCreation: string()
    .required(t('Field:required', { field: t('Field:generalName') }))
    .min(NAME_VALIDATION.min, t('Field:min', { field: t('Register:nameLabel'), min: NAME_VALIDATION.min }))
    .max(NAME_VALIDATION.max, t('Field:max', { field: t('Register:nameLabel'), max: NAME_VALIDATION.max }))
    .matches(REGEX.NOT_ONLY_WHITE_SPACES, t('Field:matches', { field: t('Register:nameLabel') }))
    .matches(REGEX.NOT_SPACES_AT_BEGIN_OR_END, t('Field:spaceAtBeginOrEnd')),
  commercialPostalCode: string()
    .required(t('Field:required', { field: t('Field:generalName') }))
    .min(NUMBER_VALIDATION.min, t('Field:min', { field: t('Field:generalName'), min: NUMBER_VALIDATION.min }))
    .max(NUMBER_VALIDATION.max, t('Field:max', { field: t('Field:generalName'), max: NUMBER_VALIDATION.max }))
    .matches(REGEX.ONLY_NUMBERS, t('Field:matches', { field: t('Field:generalName') })),
  numberRequired: string()
    .required(t('Field:required', { field: t('Register:nameLabel') }))
    .min(
      NUMBER_VALIDATION.min,
      t('Field:min', { field: t('Register:nameLabel'), min: NUMBER_VALIDATION.min })
    )
    .max(
      NUMBER_VALIDATION.max,
      t('Field:max', { field: t('Register:nameLabel'), max: NUMBER_VALIDATION.max })
    ),
  identificationNumberRequired: string().required(t('Field:required', { field: t('Field:generalName') })),
  identificationNifRequired: string().required(
    t('Field:required', { field: t('ElectronicReceiptForm:nif') })
  ),
  identificationNitRequired: string().required(
    t('Field:required', { field: t('ElectronicReceiptForm:idNIT') })
  ),
  textRequired: string().required(t('Field:required', { field: t('Field:generalName') })),
  codeValidation: number()
    .min(CODE_VALIDATION.min, t('Field:min', { field: 'Code' }))
    .max(CODE_VALIDATION.max, t('Field:max', { field: 'Code' })),
  coupon: string().max(
    COUPON_VALIDATION.max,
    t('Field:max', { field: t('Field:coupon'), max: COUPON_VALIDATION.max })
  ),
  apartmentDetails: string()
    .max(
      APARTMENT_VALIDATION[CURRENT_COUNTRY_ID].max,
      t('Field:max', {
        field: t('Field:apartment'),
        max: APARTMENT_VALIDATION[CURRENT_COUNTRY_ID].max,
        interpolation: { escapeValue: false }
      })
    )
    .when('isRequired', (isRequired, schema) => {
      if (REQUIRED_ADDRESS) {
        return schema.required(t('Field:required', { field: t('Field:generalName') }));
      }
      return schema;
    }),
  placesAddress: string()
    .max(
      MAX_PLACES_ADDRESS_NOTE[CURRENT_COUNTRY_ID].max,
      t('Field:max', {
        field: t('Delivery:note'),
        max: MAX_PLACES_ADDRESS_NOTE[CURRENT_COUNTRY_ID].max,
        interpolation: { escapeValue: false }
      })
    )
    .when('isRequired', (isRequired, schema) => {
      if (REQUIRED_ADDRESS) {
        return schema.required(t('Field:required', { field: t('Field:generalName') }));
      }
      return schema;
    }),
  expiryDate: string()
    .test(DATE_TEST_STRING, t('CheckoutPaymentMethodList:invalidMonth'), value =>
      validateExpirationMonth(value)
    )
    .test(DATE_TEST_STRING, t('CheckoutPaymentMethodList:expireDate'), value =>
      validatePastExpiryDate(value)
    ),
  cardNumber: string()
    .required(t('Field:required', { field: t('Field:generalName') }))
    .test(CARD_TEST_STRING, t('CheckoutPaymentMethodList:invalidCard'), value => validateCreditCard(value)),
  cardCode: string()
    .required(t('Field:required', { field: t('Field:cardCode') }))
    .min(
      CARD_CODE_VALIDATION.min,
      t('Field:min', {
        field: t('Field:cardCode'),
        min: CARD_CODE_VALIDATION.min
      })
    )
    .max(
      CARD_CODE_VALIDATION.max,
      t('Field:max', {
        field: t('Field:cardCode'),
        max: CARD_CODE_VALIDATION.max
      })
    )
    .matches(REGEX.ONLY_NUMBERS, t('Field:matches', { field: t('Field:cardCode') })),
  licensePlate: string()
    .max(
      LICENSE_PLATE_VALIDATION[CURRENT_COUNTRY_ID].max,
      t('Field:max', {
        field: t('DeliveryOrPickUp:papaCarLicensePlateLabel'),
        max: LICENSE_PLATE_VALIDATION[CURRENT_COUNTRY_ID].max
      })
    )
    .min(
      LICENSE_PLATE_VALIDATION[CURRENT_COUNTRY_ID].min,
      t('Field:min', {
        field: t('DeliveryOrPickUp:papaCarLicensePlateLabel'),
        min: LICENSE_PLATE_VALIDATION[CURRENT_COUNTRY_ID].min
      })
    )
    .matches(REGEX_CAR_OR_MOTO, 'Patente debe ser valida')
    .required(t('Field:required', { field: t('Field:generalName') })),
  carDescription: string()
    .max(
      PAPACAR_DESCRIPTION_VALIDATION[CURRENT_COUNTRY_ID].max,
      t('Field:max', {
        field: t('DeliveryOrPickUp:papaCarDescriptionLabel'),
        max: PAPACAR_DESCRIPTION_VALIDATION[CURRENT_COUNTRY_ID].max
      })
    )
    .required(t('Field:required', { field: t('Field:generalName') })),
  termsAndConditions: bool().oneOf([true], t('Field:termsAndConditions')),
  privacyPolicies: bool().oneOf([true], t('Field:privacyPolicies')),
  optionRequired: object().nullable().required(t('Field:optionRequired')),
  receiptNumber: string().matches(
    REGEX.ONLY_NUMBERS,
    t('Field:matches', { field: t('Contact:inputReceiptNumber') })
  ),
  tipField: string().matches(
    REGEX.ONLY_NUMBERS,
    t('Field:matches', { field: t('TipSelection:helpDelivery') })
  ),
  organizedExperience: bool().required(
    t('Field:required', { field: t('FranchisesContactForm:inputOrganizedExperience') })
  ),
  franchiseeOtherBrands: bool().required(
    t('Field:required', { field: t('FranchisesContactForm:inputFranchiseeOtherBrands') })
  ),
  personallyOperate: bool().required(
    t('Field:required', { field: t('FranchisesContactForm:inputPersonallyOperate') })
  ),
  rut: string()
    .nullable()
    .test('valid-rut', t('Contact:invalidRut'), value => (value ? validate(value) : true)),
  indetificationType: string().required('Identification type is required'),
  clientDocumentId: string().when('identificationType', (identificationType, schema) => {
    if (!identificationType) {
      return schema.notRequired();
    }
    return getIdentificationValidation(identificationType);
  })
};

export const buildValidation = validations => object().shape(validations);
