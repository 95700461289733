import i18next from 'i18next';

i18next.addResources('es_CL', 'Wizard', {
  stepNumber: 'Paso {{ number }}',
  ingredientsAmount: '{{selectionsQuantity}} de {{maxIngredients}} ingredientes'
});

i18next.addResources('es_ES', 'Wizard', {
  stepNumber: 'Paso {{ number }}',
  ingredientsAmount: '{{selectionsQuantity}} de {{maxIngredients}} ingredientes'
});

i18next.addResources('pt_PT', 'Wizard', {
  stepNumber: 'Passo {{ number }}',
  ingredientsAmount: '{{selectionsQuantity}} de {{maxIngredients}} ingredientes'
});
